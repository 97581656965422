<template>
  <div class="MoComCulture">
     <div class=" title">
      <h2 class="bigTitle"> 公司文化</h2> 
      <p class="subTitle"> Corporate culture</p> 
    </div>
<van-swipe class="my-swipe"  indicator-color="white">
  <van-swipe-item  v-for="(item, index) in itemList" :key="index" :style=" {backgroundImage: 'url(' + item.bigPic + ')'}">
     <div class = 'swiperItem'>
          <div class="item-left">
             <img :src="item.minPic" alt class="right-box-img" />
          </div>
          <div class="item-right">
             <p>{{ item.title }}</p>
             <p>{{ item.subTitle }}</p>
          </div>
     </div>
  </van-swipe-item>
</van-swipe>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  data() {
    return {
        images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
      ],
      itemList: [
        {
          bigPic: require("@/assets/images/shiming.png"),
          minPic: require("@/assets/images/shiming2.png"),
          title: "企业使命",
          subTitle: "受人之托，忠人之事"
        },
        {
          bigPic: require("@/assets/images/yuanjing.png"),
          minPic: require("@/assets/images/yuanjing2.png"),
          title: "企业愿景",
          subTitle: "好好干，狠狠玩"
        },
        {
          bigPic: require("@/assets/images/wenhua.png"),
          minPic: require("@/assets/images/wenhua2.png"),
          title: "文化核心",
          subTitle: "主动，积极"
        },
        {
          bigPic: require("@/assets/images/zhuanye.png"),
          minPic: require("@/assets/images/zhuanye2.png"),
          title: "专业精神",
          subTitle: "标准，专注"
        },
        {
          bigPic: require("@/assets/images/chushi.png"),
          minPic: require("@/assets/images/chushi2.png"),
          title: "处世心态",
          subTitle: "责任心，换位思考"
        }
      ]
    };
  },
  components: {},
  mounted() {
    new Swiper(".CultureSwiper", {
      // autoplay: {
      //   delay: 1000, // 轮播时长
      //   stopOnLastSlide: false,
      //   disableOnInteraction: false,
      // },
      autoplay: false,
      loop: true,
      // 一页出现几个
      slidesPerView: 1,
      // 设置间距
      spaceBetween: 0,
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        bulletElement: "li",
        clickable: true
      },

      slideToClickedSlide: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  methods: {}
};
</script>

<style lang="scss">
.MoComCulture {
  width: 100%;
  margin: auto;
  background: #fafafa;
  padding: 2.5rem 0 ;
  .swiperImg{
    width:80%;
    margin: auto;
  }
  .my-swipe{
    width: 80%;
    margin:auto;
    padding: 0 0 1.7188rem 0px;
    .van-swipe__indicators{
      bottom: 0.625rem;
    }
    .van-swipe__indicators .van-swipe__indicator{
      width: 0.4688rem;
      height: 0.4688rem;
      background: #888;
    }
   .van-swipe__indicators .van-swipe__indicator--active{
     background-color: #369EF7 !important;
     width: 0.9375rem;
     border-radius:0.625rem ;
      
    }
  }
  .my-swipe .van-swipe-item {
    width: 17.8125rem;
    height: 18.875rem;
    position: relative;
    background-repeat: no-repeat;
    background-size:100% 100% ;
    .swiperItem{
      width: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      padding:0.625rem 5.1563rem ;
      background: #ffffff;
      opacity: 0.5;
      .item-left{
        img{
          width: 3.125rem;
          height: 3.125rem;
          object-fit: cover;
        }
      }
      .item-right{  
        margin-left:0.9375rem ;
         p{margin:0}
         p:first-child{
           color: #222222;
           font-size:0.625rem;
         }
         p:last-child{
          font-size: 0.5rem;
          color: #666666;
         }
      }
    }
  }
.title{
    text-align: center;
     .bigTitle{
    font-size:0.9375rem;
    color: #222222;
    margin:0 0 0.1875rem;
  }
  .subTitle{
    font-size:0.5rem;
    color: #666666 ;
    margin-bottom: 1.25rem;
  }
  }
  .bigTitle{
    font-size:1.9375rem;
    color: #222222;
    margin:0 0 0.375rem;
  }
  .subTitle{
    font-size:1rem;
    color: #666666 ;
    margin-bottom: 2.5rem;
  }
  .swiper-container {}
  .content {
  display: flex;
  height: 18.75rem;
  align-items: end;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .item-warp {
    width: 100%;
    height: 6.25rem;
    display: flex;
    align-items: end;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.3125rem;

    .item-left {
      width: 50px;
      .right-box-img {
         width: 100%;
         object-fit: cover;
      }
    }

    .item-right {
      text-align: left;
      padding-left: 0.625rem;
      div:first-child {
        color: #222222;
        font-size: 0.625rem;
      }
      div:last-child {
        color: #666666;
        font-size: 0.5rem;
      }
    }
  }
}

.pagination {
  position: absolute;
  z-index: 20;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.swiper-pagination {
  bottom: 0px;
}

.swiper-pagination-switch {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #555;
  margin: 0 5px;
  opacity: 0.8;
  border: 1px solid #fff;
  cursor: pointer;
}

.swiper-slide-prev,
.swiper-slide-next {
  .content {
    overflow: hidden;
  }
}

// 轮播特殊那个
.swiper-slide-active {
  color: #fff;
  .content {
    overflow-x: auto;
  }

  .content::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
  }
}
}


</style>
