<template>
  <div>
    <div class="header">
      <div class="nav">
        <img src="xcLogo.svg"/>
         <div  class="head-icon">
          <img src="@/assets/images/email.png" @click="ckickEmail">
          <img src="@/assets/images/phone.png">
         </div>
        <div class="eject" v-if="ifEject">
          <span></span>
          <div class="ejectContent">
            <div class="left">
              <figure>
                <img src="@/assets/images/email.png">
                <figcaption>yinqian@starmob.net</figcaption>
              </figure>
              <figure>
                <img src="@/assets/images/contacts.png">
                <figcaption>尹先生</figcaption>
              </figure>
            </div>
            <div class="right">
              <figure>
                <img src="@/assets/images/consultation.png">
                <figcaption>在线咨询</figcaption>
              </figure>
              <figure>
                <img src="@/assets/images/official-account.png">
                <figcaption>关注公众号</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "XcHead",
  data() {
    return {
      isMobile: "",
      ifEject: false
    };
  },
  props:{
    ifmobile: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    ckickEmail() {
      this.ifEject = !this.ifEject;
    }
  },
  computed: {}
};
</script>
<style lang="scss">
.header {
  width: 100%;
  height:68px;
  position: relative;
  right: 0;
  left: 0;
  .nav {
    width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  overflow: visible;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}
.head-icon {
  width: 85.4px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
   img{
    width: 44.8px;
    height: 44.8px;
   }
}
//弹出层
.eject {
  background: #fff;
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 100;
  //箭头
  span {
    width: 0;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    right: 57px;
    top: -10px;
  }
  //弹框内容
  .ejectContent {
    display: flex;
    padding: 32px 38px;
    //充值figure固定样式
    figure {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      figcaption {
        font-size: 20px;
      }
    }
    //左内容
    .left {
      figure {
        display: flex;
        align-items: center;
        figcaption {
          margin-left: 17px;
        }
      }
      figure:nth-child(2) {
        margin: 10px 0;
      }
      width: 320px;
      height: 164px;
    }
    .right {
      display: flex;
      figure:nth-child(2) {
        margin-left: 30px;
      }
      figcaption {
        margin-top: 13px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
}
  width: 100%;
  height:68px;
  background: #369ef7 100%;
  position:relative;
  top:0;
  z-index:10;
}

</style>
