<template>
   <div class = 'ComProcess'>
     <div class=" title">
      <h2 class="bigTitle"> 服务合作流程</h2>
      <p class="subTitle"> Cooperation process</p>
    </div>
      <div class="process">
             <div class="ComProcessList" v-for="(item,index) in processList" :key="index" @mouseenter="processenter(index)" @mouseout="processOut">
            <div class='processImg' ref="comImg"><img :src="item.image"></div>
            <div ref="comdin" class = 'dian'></div>
            <div ref="comline" class = 'comline' v-if="index !='5'"></div>
            <div ref="comcontent" class = 'content'>
              <h2 style="font-size:28px;margin: 40px 0 3px;">{{item.id}}</h2>
              <p style="font-size:20px;margin: 0;">{{item.buzhou}}</p>
            </div>

       </div>
      </div>

   </div>
</template>

<script>
  export default{
    name:"ComProcess",
    data(){
      return{
        processList:[
          {image:require('@/assets/images/b01.png'),id:'01',buzhou:"联系我们"},
          {image:require('@/assets/images/b02.png'),id:'02',buzhou:"洽谈需求"},
          {image:require('@/assets/images/b03.png'),id:'03',buzhou:"规划方案"},
          {image:require('@/assets/images/b04.png'),id:'04',buzhou:"签订合同"},
          {image:require('@/assets/images/b05.png'),id:'05',buzhou:"方案执行"},
          {image:require('@/assets/images/b06.png'),id:'06',buzhou:"结案报告"}
          ]
      }
    },
    mounted(){
        this.$refs.comImg[0].style.opacity="1"
         this.$refs.comdin[0].style.background="#369EF7"
         this.$refs.comdin[0].style.border="none"
          this.$refs.comline[0].css={"background-color":"#369EF7"}
         this.$refs.comcontent[0].style.color="#369EF7"

    },
    methods:{
      processenter(index){
         this.$refs.comImg[index].style.opacity="1"
         this.$refs.comdin[index].style.background="#369EF7"
         this.$refs.comdin[index].style.border="none"
         this.$refs.comcontent[index].style.color="#369EF7"
         for (let i = 0; i < this.processList.length; i++) {
             if(i<=index){
               this.$refs.comImg[i].style.opacity="1"
               this.$refs.comdin[i].style.background="#369EF7"
               this.$refs.comdin[i].style.border="none"
               this.$refs.comcontent[i].style.color="#369EF7"
             } else {
              this.$refs.comImg[i].style.opacity=""
               this.$refs.comdin[i].style.background=""
               this.$refs.comdin[i].style.border=""
               this.$refs.comcontent[i].style.color=""

             }
         }
      },
      processOut(){

      }
    }
  }
</script>
<style  lang="scss">
.ComProcess{
  width:1170px;
 height: 500px;
  margin:40px auto 0;
  .title{
    text-align: center;
   .bigTitle{
    font-size:30px;
    color: #222222;
    margin:0 0 6px;
  }
  .subTitle{
    font-size:16px;
    color: #666666 ;
    margin-bottom: 40px;
  }
  }
}
.process{
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.ComProcessList{
  width: 140px;
  height:140px;
  position: relative;
  .processImg{
    opacity: 0.5;
    margin-bottom: 40px;
    img{
      box-shadow: 0px 4px 16px rgba(0,0,0,0.1000);
      width: 140px;
      height:140px;
    }
  }
  .dian{
    width: 16px;
    height: 16px;
    background: #fff;
    border:solid 1px #888;
    border-radius:50% ;
    margin: auto;
  }
  .comline{
    width: 193px;
    height: 2px;
    background: #888;
    position: absolute;
    bottom: -52px;
    right: -131px;
}
  .content{
    width: 140px;
    height:140px;
    text-align: center;
    color: #888;
  }
}

</style>
