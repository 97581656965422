<template>
  <div class="Mocard">
    <div class=" swiperCard">
      <el-carousel :interval="4000" :autoplay="false" indicator-position="none" type="card" height="200px" @change="(val)=>selectChange(val)">
        <el-carousel-item v-for="(item, index) in images" :key="index">
          <img :src="item.image" class="elImg"  :ref="'menuItem'+index">
        </el-carousel-item>
        <!-- <el-carousel-item>
      <img src="@/assets/images/01-N.png" class="elImg">
    </el-carousel-item>
    <el-carousel-item>
      <img src="@/assets/images/02-N.png" class="elImg">
    </el-carousel-item>
    <el-carousel-item>
      <img src="@/assets/images/03-N.png" class="elImg">
    </el-carousel-item> -->
      </el-carousel>
    </div>

  </div>
</template>
<script>
export default {
  name: "MoCaed",
  data() {
    return {
      checked: false,
      images: [
        { image: require("@/assets/images/01-Y.png") },
        { image: require("@/assets/images/02-N.png") },
        { image: require("@/assets/images/03-N.png") }
      ]
    };
  },
  computed: {},
  methods: {
    selectChange(e){
        for(let i=0;i<this.images.length;i++){
           if(e==i){
              this.images[i].image = require('@/assets/images/0' + (i+1) + '-Y.png')
           }else{
            this.images[i].image = require('@/assets/images/0' + (i+1) + '-N.png')
           }
        }
    }
  }
};
</script>
<style lang="scss">
.Mocard {
  width: 100%;
  background: #fafafa;

  .swiperCard {
    width: 90%;
    height: 100%;
    margin: auto;
  }

  .elImg {
    width: 100%;
    height: 9.375rem;
    object-fit: cover;
  }

  .el-carousel__container {
    height: 12.5rem !important;
  }

  // .el-carousel{
  //   height:100%
  // }
  // .el-carousel__container{
  //   height: 100% !important;
  // }
}

// .el-carousel__item {
//   top: 10px;
// }
</style>
