<template>
  <div class="comCulture-container">
      <div class=" title">
      <h2 class="bigTitle"> 公司文化</h2> 
      <p class="subTitle"> Company service content</p> 
    </div>
    <div class = 'swiperCulture'>
    <div class="left-box">
      <img :src="bigPng" alt="" class="left-box-img" />
    </div>
    <div class="right-box">
      <div
        class="item-warp"
        v-for="(item, index) in itemList"
        :key="index"
        v-on:mouseenter="enter(index)"
        v-on:mouseleave="leave()"
        :class="isEnter && index == current ? 'is-enter' : ''"
      >
        <div class="item-left">
          <img :src="item.minPic" v-moudle="" alt="" class="right-box-img" />
        </div>
        <div class="item-right">
          <div>{{ item.title }}</div>
          <div>{{ item.subTitle }}</div>
        </div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ComCulture',
  data() {
    return {
      isEnter: "",
      current: '',
      bigPng:'',
      itemList: [
        {
          bigPic: require('@/assets/images/shiming.png'),
          minPic: require('@/assets/images/shiming2.png'),
          title: '企业使命',
          subTitle: '受人之托，忠人之事',
        },
        {
          bigPic: require('@/assets/images/yuanjing.png'),
          minPic: require('@/assets/images/yuanjing2.png'),
          title: '企业愿景',
          subTitle: '好好干，狠狠玩',
        },
        {
          bigPic: require('@/assets/images/wenhua.png'),
          minPic: require('@/assets/images/wenhua2.png'),
          title: '文化核心',
          subTitle: '主动，积极',
        },
        {
          bigPic: require('@/assets/images/zhuanye.png'),
          minPic: require('@/assets/images/zhuanye2.png'),
          title: '专业精神',
          subTitle: '标准，专注',
        },
        {
          bigPic: require('@/assets/images/chushi.png'),
          minPic: require('@/assets/images/chushi2.png'),
          title: '处世心态',
          subTitle: '责任心，换位思考',
        },
      ],
    };
  },
  mounted () {
    this.enter(0)
  },
  methods: {
    enter(index) {
      this.isEnter = true;
      this.current = index;
      this.bigPng= this.itemList[index].bigPic
    },
    leave() {
      this.isEnter = false;
      this.current = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.comCulture-container {
  width: 100%;
  background: #f8f8f8;
  margin-top: 120px;
  padding: 40px 0;
.title{
    text-align: center;
   .bigTitle{
    font-size:30px;
    color: #222222;
    margin:0 0 6px;
  }
  .subTitle{
    font-size:16px;
    color: #666666 ;
    margin-bottom: 40px;
  }
  }
  .swiperCulture{
      .left-box {
    display: flex;
    align-items: center;
    .left-box-img {
      width: 571px;
      height: 614px;
    }
  }
  .right-box {
    padding: 20px;
    .item-warp {
      display: flex;
      align-items: center;

      height: 120px;
      width: 571px;

      .item-left {
        padding-left: 20px;
        .right-box-img {
          width: 100px;
        }
      }
      .item-right {
        text-align: left;
        padding-left: 20px;
      }
    }
    .is-enter {
      background-color: #ffffff;
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    }
  }
  display: flex;
  justify-content: center;
  }
 

}
</style>
