<template>
  <div class="banner">
    <div class = "bottom-img">
       <img src="@/assets/images/top_banner.png"/>
    </div>
    <!-- <div class="bottom-box">
         <img src="@/assets/images/banner-content.png" class="titleContent" />
         <img src="@/assets/images/top_pic.png" class = 'top_pic' />
    </div> -->
    <!-- <div class="bottom-img">
      <img src="@/assets/images/right-bottombanner.png" class="right-bottombanner">
       <div class="banner-components">
        <img src="@/assets/images/title_content.png" class="titleContent">
        <img src="@/assets/images/top_pic.png" class = 'top_pic'>
       </div>
    </div>
    <div class="Tcontent">

    </div> -->
  </div>
</template>
<script>
export default {
  name: "XcBanner",
    props:{
    ifmobile: {
      type: Boolean,
      default: false
    },
  },
};
</script>
<style lang = "scss">
// .banner {
//   width: 100%;
//   height: 632px;
//   .bottom-img {
//     width: 100%;
//     height: 632px;
//     top:-72px;
//     background-image:url("@/assets/images/top-banner-web.png");
//     .bottom-img-bg {
//       width: 100%;
//       height: 100%;
//     }
//      .right-bottombanner {
//       position:absolute;
//       width: 503px;
//       right: 0;
//       top:30px;
//      }
//   }
//   .banner-components {
//      width: 73%;
//      margin: 0 30% 0 20%;
//      display:flex;
//      justify-content:space-between;
//      align-items: center;
//      .titleContent{
//         width:854px;
//      }
//      .top_pic{

//      }
//   }
// }
.banner{
  width: 100%;
  background:#fafafa;
  margin: 0;
  padding: 0;
  overflow:hidden;
  position: relative;
   img{
  width: 100%;
  object-fit: cover;
}
.bottom-box{
  height: 80%;
  background: #fff;
  margin:40px 20%;
  display: flex;
  .titleContent{
    width: 854px;
    height: 317px;
  }
}
}

</style>
