<template>
  <div class="MoProcess">
    <div class=" title">
      <h2 class="bigTitle"> 服务合作流程</h2>
      <p class="subTitle"> Cooperation process</p>
    </div>
    <div class="ProcessList">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="(item,index) in ProcessList" :key="index">
           <img  :src="item.images" />
           <p style="color: #369EF7; font-size:1.125rem;margin-top: 2.5rem;">{{item.id}}</p>
           <p style="color: #369EF7; font-size:0.625rem;">{{item.content}}</p>
        </van-grid-item>
      </van-grid>
    </div>

    <!-- <ul>
      <li>
        <div class="img">
          <img src="@/assets/images/b01.png" alt>
        </div>
        <p class="textBox">01</p>
        <p class="textBox">联系我们</p>
      </li>
      <li>
        <div class="img">
          <img src="@/assets/images/b02.png" alt>
        </div>
        <p class="textBox">02</p>
        <p class="textBox">洽谈需求</p>
      </li>
      <li>
        <div class="img">
          <img src="@/assets/images/b03.png" alt>
        </div>
        <p class="textBox">03</p>
        <p class="textBox">规划方案</p>
      </li>
      <li>
        <div class="img">
          <img src="@/assets/images/b04.png" alt>
        </div>
        <p class="textBox">04</p>
        <p class="textBox">签订合同</p>
      </li>
      <li>
        <div class="img">
          <img src="@/assets/images/b05.png" alt>
        </div>
        <p class="textBox">05</p>
        <p class="textBox">方案执行</p>
      </li>
      <li>
        <div class="img">
          <img src="@/assets/images/b03.png" alt>
        </div>
        <p class="textBox">06</p>
        <p class="textBox">结案报告</p>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: "MoProcess",
  data(){
    return{
      ProcessList:[
        {images: require("@/assets/images/b01.png"),id:'01',content:'联系我们'},
        {images: require("@/assets/images/b02.png"),id:'02',content:'洽谈需求'},
        {images: require("@/assets/images/b03.png"),id:'03',content:'规划方案' },
        {images: require("@/assets/images/b04.png"),id:'04',content:'签订合同' },
        {images: require("@/assets/images/b05.png"),id:'05',content:'方案执行' },
        {images: require("@/assets/images/b06.png"),id:'06',content:'结案报告' }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.MoProcess {
  width: 100%;
  padding: 2.5rem 0 0.625rem;
  .ProcessList{
    width:80%;
    margin: auto;
  }
  p{
    margin: 0;
  }
.title{
    text-align: center;
     .bigTitle{
    font-size:0.9375rem;
    color: #222222;
    margin:0 0 0.1875rem;
  }
  .subTitle{
    font-size:0.5rem;
    color: #666666 ;
    margin-bottom: 1.25rem;
  }
  }
}

ul {
  /* 清除默认样式 */
  list-style: none;
  /* 显示为弹性布局 */
  display: flex;
  /* 允许换行 */
  flex-flow: wrap;
  // margin-left: 30px;
  padding: 0 20px;
}

/* 子元素 */
li {
  /* 宽高都是百分之33*/
  width: 33%;
  height: 33%;
  /* 给个合适的外边距 */
  margin: 0.5px;
  align-items: center;
  /* 深蓝渐变 */
  background: var(--黛蓝);
}

li {
  text-align: center;
  margin-bottom: 70px;
}

img {
  width: 80%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

img:hover {
  animation: tada 1s 0.2s ease both;
  -moz-animation: tada 1s 0.2s ease both;
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}

.textBox {
  font-size: 16px;
  color: #2452d2d3;
  font-weight: bolder;
  line-height: 12px;
}
</style>