<template>
  <div id="app">
    <div class="container">
      <div class="amap-page-container">
        <el-amap vid="amapDemo" :center="center" :zoom="zoom" :mapStyle="mapStyle" :plugin="plugin" events="events" pitch-enable="false">
          <el-amap-text v-for="(marker, index) in texts" :key="index" :position="marker.position" :text="marker.text"></el-amap-text>
        </el-amap>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AmapPage',
  data: function () {
    return {
      data: [],
      zoom: 17,
      mapStyle: 'normal',
      center: [108.879667, 34.226269],
      texts: [
        {
          position: [108.879667, 34.226399],
          id: 1,
          text: '竹园 天寰国际',
        },
      ],

      /*一些工具插件*/
      plugin: [
        {
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 100, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: 'LB', //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: 'all',
          scrollWheel: true,
          touchZoom: true,
          resizeEnable: true,
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 100wh;
  .amap-page-container {
    height: 400px;
    margin: 15px;

    /deep/.amap-logo {
      display: none;
      opacity: 0 !important;
    }
    /deep/.amap-copyright {
      opacity: 0;
    }
  }
}
</style>
