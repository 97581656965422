<template>
  <div class='customers'>
      <div class=" title">
      <h2 class="bigTitle"> 合作客户</h2>
      <p class="subTitle"> Cooperative customers</p>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane class="game" name="first">
        <div slot="label">
          <img src="@/assets/images/game-Y.png" v-if="activeName == 'first'">
          <img src="@/assets/images/game-N.png" v-if="activeName == 'second'" />
        </div>
        <div class="custorms-game">
          <img v-for="item in 18" :key="item" :class="currentIndex === item ? 'shadow' : ''" @mouseover="mouseover(item)"
            @mouseout="mouseout(item)" :src="require('@/assets/images/game/game-' + item + '.png')" />
        </div>
      </el-tab-pane>
      <el-tab-pane name="second">
        <div slot="label">
          <img src="@/assets/images/unGame-N.png" v-if="activeName == 'first'" />
          <img src="@/assets/images/unGame-Y.png" v-if="activeName == 'second'" />
        </div>
        <div class="custorms-nogame">
          <img v-for="item in 6" :key="item" :class="currentIndex === item ? 'shadow' : ''" @mouseover="mouseover(item)"
            @mouseout="mouseout(item)" :src="require('@/assets/images/nogame/nogame-' + item + '.png')" />
        </div>
      </el-tab-pane>
    </el-tabs>
    <div id="Statistics" class="Statistics" >
        <div>
            <p style="margin-left:79px;color: #369EF7 ; font-size: 70px;">{{custormNum}}</p>
            <p style="margin-left:79px;">累计服务客户</p>
        </div>

        <p class = "dian"></p>
        <div>
            <p style="color: #369EF7 ; font-size: 70px;">{{satisfied}}%</p>
             <p>客户满意度</p>
        </div>
        <p class = "dian"></p>
        <div>
          <p style="color: #369EF7 ; font-size: 70px;">80+</p>
          <p>行业用户选择</p>
        </div>
        <p class = "dian"></p>
        <div>
          <p style="color: #369EF7 ; font-size: 70px;">{{brand}}</p>
          <p>覆盖品牌数量</p>
        </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'XcCustomers',
  data() {
    return {
      currentIndex: '',
      activeName: 'first',
      custormNum:0,
      satisfied:0,
      brand:0,
      target:'',
      ifscroll:0,
    };
  },
  mounted(){
    // window.addEventListener('scroll', function () {
    //   // console.log(document.getElementById('Statistics').getBoundingClientRect().top)
    //   //     console.log( document.documentElement.scrollTop)
    //  // console.log(document.getElementById('Statistics').offsetTop)
    //
    //   // if(Number(document.getElementById('Statistics').getBoundingClientRect().top)<900){
    //   //     this.ifscroll = 1
    //   //      console.log(this.ifscroll )
    //   // }
    //
    // })
    this.custormNumAdd()
    this.satisfiedAdd()
    this.brandAdd()
  },
  // watch:{
  //   ifscroll:function (curVal, oldVal){
  //      console.log(curVal,oldVal)
  //     if(this.ifscroll){
  //       this.custormNumAdd()
  //       this.satisfiedAdd()
  //       this.brandAdd()
  //     }
  //   }
  // },
  methods: {
    mouseover(item) {
      this.currentIndex = item
    },
    mouseout() {
      this.currentIndex = ''
    },
    custormNumAdd(){
         let Num = 0
         const Totls =setInterval(() => {
        Num++
          this.custormNum = Num
        if(Num == 169){
          clearInterval(Totls)
          return
        }
     }, 10);
    },
    satisfiedAdd(){
         let Num = 0
         const Totls =setInterval(() => {
        Num++
          this.satisfied = Num
        if(Num == 100){
          clearInterval(Totls)
          return
        }
     }, 10);
    },
    brandAdd(){
      let Num = 0
      const Totls =setInterval(() => {
        Num++
          this.brand = Num
        if(Num == 12){
          clearInterval(Totls)
          return
        }
     }, 10);
    }
  }
}
</script>

<style lang="scss">
.customers {
  width: 1170px;
  margin: auto;
  height: 800px;
  .title{
    text-align: center;
   .bigTitle{
    font-size:30px;
    color: #222222;
    margin:0 0 6px;
  }
  .subTitle{
    font-size:16px;
    color: #666666 ;
    margin-bottom: 40px;
  }
  }
  #tab-first {
    padding: 0 283px 0 341px;
  }

  .el-tabs__content {
    padding: 80px 0 0;
  }

  .custorms-game img {
    margin-top: 40px;
    width: 190px;
    margin-right: 3px;
  }

  .custorms-nogame {
    width: 598px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .custorms-nogame img {
    margin-top: 40px;
    width: 190px;
  }
   .el-tab-pane{
     height: 400px;
   }
  .shadow {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1600);
  }

  .Statistics{
    width:1170px;
    height: 200px;
    background:url("@/assets/images/custormBg.png") no-repeat;
    background-size:100% 100% ;
    display: flex;
    align-items: center;
    p{
      margin: 0;
      padding: 0;
      text-align: center;
    }
      .dian{
    width: 15px;
    height: 15px;
    background: #369EF7;
    border-radius: 50%;
    margin:0 51px 0 81px ;
  }
  }
}
</style>
