import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/reset.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


import AMap from 'vue-amap';
Vue.use(AMap);

AMap.initAMapApiLoader({
  key: '736c47a7cad90a3f27d5bb13b3100749',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
  uiVersion: '1.0',
});

Vue.use(ElementUI);
Vue.config.productionTip = false;

const baseSize = 32;
function setRem() {
  // 当前页面宽度相对于 750 宽的缩放比例
  const scale = document.documentElement.clientWidth / 750;
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
}
// 初始化
setRem();
window.onresize = function () {
  setRem();
};


new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
