<template>
  <div class="MoAboutUs">
    <div class=" title">
      <h2 class="bigTitle"> 关于我们</h2>
      <p class="subTitle"> About us</p>
    </div>
    <div class="about">
      <div class="aboutContent">
        <P>陕西星璨互娱文化传播有限公司简称<span>"星璨互娱"</span>,创立于2018年。是一家专注于移动互联网领域的第三方品牌生态服务商</p>
        <p>核心业务包括<span>内容运营、线上传播、舆情管理、数据优化、新媒体服务</span>等</p>
        <P>星璨互娱自成立以来“本着业内最专业的整合运营营销方案提供商”的营销目标，帮助企业<span>提升运营质量，加强品牌价值，巩固客情关系；</span></p>
        <P>本着“受人之托，忠人之事”的原则，为企业提供更加<span>专业，优质</span>的定制化专属服务。</p>
      </div>

    </div>
    <div class='erwei'>
      <div class="ejectContent">
        <div class="left">
          <figure>
            <img src="@/assets/images/email.png">
            <figcaption>yinqian@starmob.net</figcaption>
          </figure>
          <figure>
            <img src="@/assets/images/contacts.png">
            <figcaption>尹先生</figcaption>
          </figure>
        </div>
        <div class="right">
          <figure>
            <img src="@/assets/images/consultation.png">
            <figcaption>在线咨询</figcaption>
          </figure>
          <figure>
            <img src="@/assets/images/official-account.png">
            <figcaption>关注公众号</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoAboutUs"
};
</script>

<style lang="scss" scoped>
.MoAboutUs {
  width: 100%;
  margin: 0 auto 3.125rem;

  .title {
    text-align: center;

    .bigTitle {
      font-size: 0.9375rem;
      color: #222222;
      margin: 0 0 0.1875rem;
    }

    .subTitle {
      font-size: 0.5rem;
      color: #666666;
      margin-bottom: 1.25rem;
    }
  }

  .about {
    width: 80%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    font-size: 0.5625rem;
    color: #222222;
    background: #e6f7ff54;
    border-radius: 0.4688rem;

    .aboutContent{
      margin: 1.25rem 1.5625rem;
    }

    span {
      color: #369EF7;
    }
  }

  .erwei {
    width: 80%;
    margin: auto;

    .ejectContent {
      .left {
        display: flex;
        flex-wrap: wrap;
      }

      .left img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.1563rem;
        object-fit: cover;
      }

      .left figcaption {
        color: #222222;
        font-size: 0.625rem;
      }

      .left figure {
        display: flex;
        align-items: center;
        margin-left: 1.25rem;
      }

      .right {
        display: flex;
        justify-content: space-around;

        figure {
          margin: 0;
        }

        figcaption {
          text-align: center;
        }
      }

      .right img {
        width: 6.375rem;
        height: 6.375rem;
        object-fit: cover;
      }
    }
  }
}
</style>