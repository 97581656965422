<template>

  <div class="TimeWeb">
   <div class=" title">
      <h2 class="bigTitle">大事件时间轴</h2> 
      <p class="subTitle">Timeline of major events</p> 
    </div>
  <div class="swiper-container history-swiper">
    <div class="swiper-wrapper">
      <div   class="swiper-slide" v-for="(item,index) in history" :key="index" @mouseover="TimeEnter(index)" @mouseout="TimeOut(index)">
        <div :class="isEnter && index == current ? 'is-eventEnter' : ''" class="eventTitle"><p style="width:100px">{{item.event}}</p></div>
        <span class="time" :class="isEnter && index == current ? 'is-TimeEnter' : ''">{{item.Time}}</span>
        <div class="ifcheck" :class="isEnter && index == current ? 'is-check' : ''" ></div>
      </div>
    </div>
      <div class="swiper-button-prev" ref="prev" @mouseover="mouseoverPre" @mouseout="mouseoutPre"></div>
      <div
        class="swiper-button-next"
        ref="next"
         @mouseover="mouseovernext"
        @mouseout="mouseoutnext"
      ></div>
  </div>

  </div>

</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: 'TimeEven',
  data(){
    return{
      current:0,
      isEnter:false,
       history: [
        { event: '成立', Time: '2018年2月' },
        { event: '评为高新区雏鹰企业', Time: '2019年度' },
        { event: '评为高新区瞪羚企业', Time: '2020年度' },
        { event: '高新区认定为科技型中小企业', Time: '2020年7月' },
        { event: '陕西省认定为高新技术企', Time: '2020年12月' },
      ]
    }
  },
  methods:{
    TimeEnter(index){
       this.isEnter = true;
       this.current = index;
    },
      mouseoverPre() {

      this.$refs["prev"].style.background = "#369EF7";
      this.$refs["prev"].style.color = "#fff";
    },
    mouseoutPre() {
      this.$refs["prev"].style.background = "#fff";
      this.$refs["prev"].style.color = "#333";
    },
    mouseovernext() {
      this.$refs["next"].style.background = "#369EF7";
      this.$refs["next"].style.color = "#fff";
    },
    mouseoutnext() {
      this.$refs["next"].style.background = "#fff";
      this.$refs["next"].style.color = "#333";
    }
  },
  mounted() {
    new Swiper(".history-swiper", {
      autoplay: false,
           // 一页出现几个
      slidesPerView: 4,
      // 如果需要分页器
      // pagination: {
      //   el: ".swiper-pagination",
      //   bulletElement: "li",
      //   clickable: true
      // },

      slideToClickedSlide: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },

}
</script>

<style lang="scss" scoped>
.TimeWeb {
  width: 100%;
  height: 400px;
  margin: 40px 0;
   .title{
    text-align: center;
   .bigTitle{
    font-size:30px;
    color: #222222;
    margin:0 0 6px;
  }
  .subTitle{
    font-size:16px;
    color: #666666 ;
    margin-bottom: 40px;
  }
  }
.history .title {
    font-size: 30px;
    font-weight: normal;
    color: #333;
    line-height: 136px;
    text-align: center;
}

.history-swiper {
    padding: 0 20px;
    height: 410px;
    width: 1410px;
    overflow: hidden;
    background-repeat: repeat-x;
    background-position: left center;
}
.history-swiper .swiper-wrapper {
    position: relative;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
}
.history-swiper .swiper-slide {
    position: relative;
    opacity: 1;
    margin:0 80px;
    width: 184px !important;
}
.ifcheck{
     width: 41px;
     height:41px;
     background: url(@/assets/images/nogouxuan.png);
     background-size:100% 100%;
     margin: auto;
}
.is-check{
  background: url(@/assets/images/gouxuan.png);
}
.Timeline{
    width: 128px;
    border: 1px dotted #888;
    position: absolute;
    bottom: 237px;
    right: -149px;

}
.eventTitle{
    width: 184px;
    height: 108px;
    display: flex;
    align-items:center;
    flex-wrap: wrap;
    margin:0 auto 20px;
    color: #8888;
    padding: 10px 0;
    p{
      margin: auto;
      font-size: 18px;
      text-align: center;
    }
}
.time {
    display: block;
    font-size: 18px;
    width: 106px;
    margin:0 auto 20px;
    text-align: center;
    color:#8888;
    border-bottom: 1px solid #8888;
}

.is-eventEnter{
  background: url("@/assets/images/blueBag.png");
  background-size:100% 100% ;
  color: #fff;
}
.is-TimeEnter{
  color: #369EF7;
    border-bottom: 1px solid #369EF7;
}
.swiper-button-next {
  width: 80px;
    height: 80px;
    border-radius: 50%;
    right: 23px;
    left: auto;
    top: 105px;
   background: #fff;
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1000);
    color: #333;
    outline: none;
}
.swiper-button-prev {
    left: 23px;
    top: 105px;
    right: auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1000);
    color: #333;
    outline: none;
    position: absolute;
}
}
</style>