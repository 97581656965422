<template>
<div id="app">
  <div class="MoFoot">
    <div class="foot-nav">
        <P>地址:西安市雁塔区科技二路竹园·天寰国际</P>
        <div class="foot-nav-img">
            <div>
               <p>联系人:尹先生</p>
            </div>
             <img src="xcLogo.svg" class="logo"/>
        </div>
    </div>
    <div class="showEmail">
       <a href="./xiniuGame.html">Copyright © 2019 陕西星璨互娱文化传播有限公司</a> |
         <a href="https://beian.miit.gov.cn/">陕ICP备18012961号-1</a> |
      <a @click="clickImg">陕网文【2019】0765-021号</a> |
      <a @click="clickImg2">陕B2-20190105</a>
      <a  @click="clickImg"><img src="@/assets/images/xiniuGame/wen.png" style="width: 30px"></a>
    </div>
  </div>
</div>

</template>

<script>
  export default {
    name:'MoFoot',
    methods:{
      clickImg(){
        let str = require("@/assets/images/xiniuGame/jyz.jpg")
        window.open(str)
      },
      clickImg2(){
        let str = require("@/assets/images/xiniuGame/dxjyz.png")
        window.open(str)
      }
    }
  }
</script>

<style lang="scss" scoped>
.MoFoot{
  width:100%;
  background: #369EF7;
  position: relative;
  padding: 0.625rem 0;
  overflow: hidden;
  right: 0;
  left: 0;
  .foot-nav{
    width: 80%;
    margin: auto;
    border-bottom:solid 1px #CCCCCC;
    padding-bottom: 0.625rem;
    text-align: left;
    overflow: hidden;
    color: #FFFFFF;
    font-size: 0.75rem;
  }
  .foot-nav-img{
    display: flex;
    justify-content: space-between;
  }
  .logo{
    width:  8.1875rem;
  }
  .showEmail{
    display: flex;
    flex-wrap: wrap;
    margin:0 2.5rem;
    padding: 0.9375rem 0 0;
    font-size: 0.5rem;
    text-align: center;
    color: #fff;
   a{
      margin:0 0.3125rem;
      color: #fff;
    }
    a:hover{
      color:#403BD6;
    }
  }
}
</style>
