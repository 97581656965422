<template>

  <div class="tocard">
      <div class="card-list" v-for="(item,index) in images" :key = 'index' >
          <img  :src = "item.ischecked?item.image2:item.image" :ref="'changeH${index}'" @mouseenter = "changeImg(index,item)"  @mouseout="changeOut(index,item)"  :class = '{checked:item.ischecked }'/>
      </div>
    </div>
</template>
<script>
export default {
  name: "XcCaed",
  data(){
    return{
      checked:false,
      images:[
         {image:require('@/assets/images/01-N.png'),image2:require('@/assets/images/01-Y.png'),ischecked : false},
         {image:require('@/assets/images/02-N.png'),image2:require('@/assets/images/02-Y.png'),ischecked : false},
         {image:require('@/assets/images/03-N.png'),image2:require('@/assets/images/03-Y.png'),ischecked : false}
      ]
    }
  },
  computed:{

  },
  methods:{
     changeImg(index,item){
        item.ischecked = true
        if(item.ischecked){
          item.image = require('@/assets/images/0' + (index+1) + '-Y.png')
           if(index == 0){
             this.$refs['changeH${index}'][index].style.top = '-153px';
           } else {
             this.$refs['changeH${index}'][index].style.top = '-118px';
           }
        } else {
         item.image = require('@/assets/images/0' + (index+1) + '-N.png')
           if(index == 0){
             this.$refs['changeH${index}'][index].style.top = '-143px';
           } else {
             this.$refs['changeH${index}'][index].style.top = '-108px';
           }
        }
     },
     changeOut(index, item) {
       item.ischecked = false
      item.image = require("@/assets/images/0" + (index + 1) + "-N.png");
      if (index == 0) {
        this.$refs["changeH${index}"][index].style.top = "-143px";
      } else {
        this.$refs["changeH${index}"][index].style.top = "-108px";
      }
     }
  }
};
</script>
<style lang="scss">
// .tocard {
//   width: 58%;
//   height: 150px;
//   margin: auto;
//   position: relative;
//   .card-list{
//     position: absolute;
//   }
//    .checked{
//       padding:10px 0;
//    }
// }
.tocard {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  img{
  position: absolute;
  display: block;
  }
  .card-list {
  position:relative;
  align-items: flex-end;
  border-radius: 10px;
  // box-shadow: 0 20px 40px 0 rgb(153 169 191 / 30%);
  cursor: pointer;
  height: 150px;
  width: 350px;
  margin:10px;
  transition: all 0.3s;
  img{
    top:-108px;
    left:-45px;
  }
}
  .card-list:first-child img{
    top:-143px;
    left:-45px;
  }
}
</style>
