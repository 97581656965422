<template>
  <div class="MoTimeEven">
    <div class=" title">
      <h2 class="bigTitle"> 大事件时间轴</h2> 
      <p class="subTitle"> Timeline of major events</p> 
    </div>
    <div class="app-container">
      <div class="swiper-container TimeSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide1 divs" v-for="(item, index) in history" :key="index">
            <div>
               <div class="event">
                <p>{{item.event}}</p> 
               </div>
              <p class="time">{{item.Time}}</p>
              <div class="changeX"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: 'MoTimeEven',
  data() {
    return {
      history: [
        { event: '成立', Time: '2018年2月' },
        { event: '评为高新区雏鹰企业', Time: '2019年度' },
        { event: '评为高新区瞪羚企业', Time: '2020年度' },
        { event: '高新区认定为科技型中小企业', Time: '2020年7月' },
        { event: '陕西省认定为高新技术企', Time: '2020年12月' },
      ]
    };
  },
  components: {},
  mounted() {
    new Swiper(".TimeSwiper", {
      // autoplay: {
      //   delay: 1000, // 轮播时长
      //   stopOnLastSlide: false,
      //   disableOnInteraction: false,
      // },
      autoplay: false,
      loop: true,
      // 一页出现几个
      slidesPerView: 3,
      // 设置间距
      spaceBetween: 30,
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        bulletElement: "li",
        clickable: true
      },

      slideToClickedSlide: true,
      centeredSlides: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  updated() {

  },
  methods: {
    mouseoverPre() {
      console.log("1111");
      this.$refs["prev"].style.background = "#369EF7";
      this.$refs["prev"].style.color = "#fff";
    },
    mouseoutPre() {
      console.log("1111");
      this.$refs["prev"].style.background = "#fff";
      this.$refs["prev"].style.color = "#333";
    },
    mouseovernext() {
      console.log("1111");
      this.$refs["next"].style.background = "#369EF7";
      this.$refs["next"].style.color = "#fff";
    },
    mouseoutnext() {
      console.log("1111");
      this.$refs["next"].style.background = "#fff";
      this.$refs["next"].style.color = "#333";
    }
  }
};
</script>

<style lang="scss" scoped>
.MoTimeEven {
    padding:40px 0;
    width: 100%;
.title{
    text-align: center;
     .bigTitle{
    font-size:0.9375rem;
    color: #222222;
    margin:0 0 0.1875rem;
  }
  .subTitle{
    font-size:0.5rem;
    color: #666666 ;
    margin-bottom: 1.25rem;
  }
  }
  .bigTitle{
    font-size:1.9375rem;
    color: #222222;
    margin:0 0 0.375rem;
  }
  .subTitle{
    font-size:1rem;
    color: #666666 ;
    margin-bottom: 2.5rem;
  }
  .swiper-button-next {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    right: -3.6875rem;
    left: auto;
    background: #fff;
    color: #333;
    outline: none;
  }

  .swiper-button-prev {
    left: -3.6875rem;
    right: auto;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: #fff;
    color: #333;
    outline: none;
  }

  .app-container {
    width: 90%;
    margin: auto;
    position: relative;
  }

  .divs {
    // 向上挤出40px
    margin-top: 40px;
    width: 100%;
  }
  .swiper-slide{
     margin-right: 0.3125rem;
  }
  .event{
    font-size: 0.5625rem;
    width:5.75rem;
    height:3.375rem;
    color: #888888;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items:center;
    p{
      margin: auto;
    }
  }
  .time{
    font-size: 0.625rem;
    text-align: center;
    color:#888888;
    border-bottom: 1px solid #888888;
  }
  .changeX{
    width: 1.25rem;
    height:1.25rem;
    border-radius: 50%;
    background: url(@/assets/images/nogouxuan.png);
    background-size:cover;
    margin: auto;
  }
  .content {
    text-align: left;
    height: 100%;
    padding: 0 0.75rem;

  }


  .pagination {
    position: absolute;
    z-index: 20;
    bottom: 0.3125rem;
    width: 100%;
    text-align: center;
  }

  .swiper-pagination {
    bottom: 1.9375rem;
  }

  .swiper-pagination-switch {
    display: inline-block;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.25rem;
    background: #555;
    margin: 0 0.1563rem;
    opacity: 0.8;
    border: 1px solid #fff;
    cursor: pointer;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    .content {
      overflow: hidden;
    }
  }

  // 轮播选中那个
  .swiper-slide-active {
     .event{
       color: #fff;
      font-size: 0.5625rem;
      text-align: center;
      background: linear-gradient(180deg, #95CEFF 0%, #369EF7 100%);
      border-radius: 0.625rem;
      background-repeat:no-repeat ;
      background-size:100% 100% ;
     }
     .time{
      color:#369EF7;
      border-bottom:1px solid #369EF7;
     }
    .content {
      overflow-x: auto;
    }
    .changeX{
     background: url(@/assets/images/gouxuan.png);
    background-size:cover
    }
    .content::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 2px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
  }
}
</style>