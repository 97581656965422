<template>
  <div class="home">
    <!-- 头部 -->
    <XcHead v-if ="!isMobile"></XcHead>
    <Mohead v-if ="isMobile"></Mohead>
    <!-- banner -->
    <XcBanner v-if ="!isMobile"></XcBanner>
    <MoBanner v-if ="isMobile"></MoBanner>
    <!-- card -->
    <XcCard v-if ="!isMobile"></XcCard>
    <MoCard  v-if ="isMobile"></MoCard>
    <!-- 公司服务内容 -->
    <!-- <BorderText color="#FAFAFA" content="公司服务内容" subContent="Company service content"></BorderText> -->
    <XcService  v-if ="!isMobile"></XcService>
    <MoComService v-if ="isMobile"></MoComService>
    <!-- 服务合作流程 -->
    <!-- <BorderText content="服务合作流程" subContent="Cooperation process"></BorderText> -->
    <Xcprocess  v-if ="!isMobile"></Xcprocess>
    <MoProcess  v-if ="isMobile"></MoProcess>
    <!-- 合作客户 -->
    <!-- <BorderText content="合作客户" subContent="Cooperative customers"></BorderText> -->
    <XcCustomers v-if ="!isMobile"></XcCustomers>
    <MoCustomers v-if ="isMobile"></MoCustomers>
    <!-- 公司文化 -->
    <ComCulture  v-if ="!isMobile"></ComCulture>
    <MoComCulture  v-if ="isMobile"></MoComCulture>
    <!-- 大事件时间轴 -->
    <TimeEven v-if ="!isMobile"></TimeEven>
    <MoTimeEven  v-if ="isMobile"></MoTimeEven >
    <!-- 关于我们 -->
    <AboutUs v-if ="!isMobile"></AboutUs>
    <Moabouts v-if ="isMobile"></Moabouts>
    <!-- 地图 -->
    <ComMap></ComMap>
    <!--脚部 -->
    <FootNav v-if ="!isMobile"></FootNav>
    <MoFoot  v-if ="isMobile"></MoFoot>
  </div>
</template>

<script>
import { deviceEnquire } from '@/assets/js/utils/device'
import XcHead from "./assembly/head";
import XcBanner from "./assembly/banner";
import XcCard from "./assembly/Card";
import XcService from "./assembly/ComService";
import Xcprocess from "./assembly/Comprocess"
import XcCustomers from "./assembly/Customers"
import ComCulture from "./assembly/ComCulture"
import TimeEven from "./assembly/TimeEven"
import AboutUs from "./assembly/AboutUs"
import ComMap from "./assembly/ComMap"
import FootNav from "./assembly/FootNav"

//移动
import Mohead from "./mobile/Mohead"
import MoCard from "./mobile/Mocard";
import MoBanner from "./mobile/Mobanner";
import MoComService from "./mobile/MoComService";
import MoCustomers from "./mobile/Mocustomers"
import MoProcess from "./mobile/Moprocess";
import MoComCulture from "./mobile/MoComCulture";
import MoTimeEven from "./mobile/MoTimeEven";
import Moabouts from "./mobile/Moabouts"
import MoFoot from "./mobile/MoFoot"
export default {
  name: "HomeView",
  components: {
    XcHead,
    XcBanner,
    MoBanner,
    XcCard,
    MoCard,
    XcService,
    Xcprocess,
    XcCustomers,
    MoCustomers,
    ComCulture,
    TimeEven,
    AboutUs,
    ComMap,
    FootNav,
    Mohead,
    MoComService,
    MoProcess,
    MoComCulture,
    MoTimeEven,
    Moabouts,
    MoFoot
  },
  data(){
    return{
      isMobile:false,
      dk:'',
    }
  },
   mounted () {
        deviceEnquire(deviceType => {
          this.dk = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
          if(deviceType == 'DESKTOP' || !this.dk){
              this.isMobile = false;
          } else if(deviceType != 'DESKTOP' && this.dk){
              this.isMobile = true;
          }
        })
    },

};
</script>
