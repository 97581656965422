<template>
  <div class="Mocustomers">
     <div class=" title">
      <h2 class="bigTitle"> 合作客户</h2> 
      <p class="subTitle"> Cooperative customers</p> 
    </div>
<van-tabs @click="changeClick">
  <van-tab >
      <div slot="title">
           <img src="@/assets/images/game-Y.png" v-if="activeName == 'first'">
           <img src="@/assets/images/game-N.png" v-if="activeName == 'second'">
      </div>
      <!-- <div class="custorms-game">
          <img
            v-for="item in 18"
            :key="item"
            :class="currentIndex === item ? 'shadow' : ''"
            @mouseover="mouseover(item)"
            @mouseout="mouseout(item)"
            :src="require('@/assets/images/game/game-' + item + '.png')"
          >
        </div> -->
        <van-grid :column-num="3" :border="false">
          <van-grid-item v-for="item in 18" :key="item" >
             <van-image  :src="require('@/assets/images/game/game-' + item + '.png')" />
          </van-grid-item>
        </van-grid>
      <div>

      </div>
    </van-tab>
  <van-tab>
    <div slot="title">
          <img src="@/assets/images/unGame-N.png" v-if="activeName == 'first'">
          <img src="@/assets/images/unGame-Y.png" v-if="activeName == 'second'">
        </div>
     <van-grid :column-num="3" :border="false">

      <van-grid-item v-for="item in 6" :key="item">
             <van-image  :src="require('@/assets/images/nogame/nogame-' + item + '.png')" />
          </van-grid-item>
        </van-grid>
    </van-tab>
</van-tabs>

<van-grid direction="horizontal" :column-num="2" class = "Statistics" :border="false">
  <van-grid-item>
    <div slot="text">
      <h2 class = 'cusNum'>{{custormNum}}</h2>
       <p class = 'ms'>累计服务客户</p>
    </div>
  </van-grid-item>
    <van-grid-item>
    <div slot="text">
      <h2 class = 'cusNum'>{{satisfied}}%</h2>
       <p class = 'ms'>客户满意度</p>
    </div>
  </van-grid-item>
    <van-grid-item>
    <div slot="text">
      <h2 class = 'cusNum'>80+</h2>
       <p class = 'ms'>行业用户选择</p>
    </div>
  </van-grid-item>
    <van-grid-item>
    <div slot="text">
      <h2 class = 'cusNum'>{{brand}}</h2>
       <p class = 'ms'>覆盖品牌数量</p>
    </div>
  </van-grid-item>
</van-grid>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
export default {
  name: "MoCustomers",
  data() {
    return {
      currentIndex: "",
      activeName: "first",
      custormNum: 0,
      satisfied: 0,
      brand: 0
    };
  },
  compoments:[
    Tab,Tabs
  ],
  mounted() {
    this.custormNumAdd(), this.satisfiedAdd(), this.brandAdd();
  },
  methods: {
    changeClick(name) {
      if(name == 0){
        this.activeName = 'first'
      } else {
         this.activeName = 'second'
      }
    },
    mouseover(item) {
      this.currentIndex = item;
    },
    mouseout() {
      this.currentIndex = "";
    },
    custormNumAdd() {
      let Num = 0;
      const Totls = setInterval(() => {
        Num++;
        this.custormNum = Num;
        if (Num == 169) {
          clearInterval(Totls);
          return;
        }
      }, 10);
    },
    satisfiedAdd() {
      let Num = 0;
      const Totls = setInterval(() => {
        Num++;
        this.satisfied = Num;
        if (Num == 100) {
          clearInterval(Totls);
          return;
        }
      }, 10);
    },
    brandAdd() {
      let Num = 0;
      const Totls = setInterval(() => {
        Num++;
        this.brand = Num;
        if (Num == 12) {
          clearInterval(Totls);
          return;
        }
      }, 10);
    }
  }
};
</script>

<style lang="scss">
.Mocustomers {
  width: 100%;
  margin: 2.5rem auto;
  padding: 0 0 2.5rem;
  //标题
.title{
    text-align: center;
     .bigTitle{
    font-size:0.9375rem;
    color: #222222;
    margin:0 0 0.1875rem;
  }
  .subTitle{
    font-size:0.5rem;
    color: #666666 ;
    margin-bottom: 1.25rem;
  }
  }
  .van-tabs--line .van-tabs__wrap{
    height: auto;
  }
  .van-tab__text{
    img{
      height: 4.375rem;
      object-fit: cover;
    }
  }
  //客户量
  .Statistics {
    margin:0 40px;
    height: 10.0625rem;
    background: url("@/assets/images/custormBg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
    .van-grid-item__content{
      background: none;
    }
    .cusNum {
      margin: 0;
      text-align: center;
      font-size: 1.25rem;
      color: #369EF7;
    }
     .ms{
        margin: 0;
        font-size: 0.5625rem;
        color: #888888;
     }
  }
}
</style>