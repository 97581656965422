<template>
  <div class="Moservice">
    <div class=" title">
      <h2 class="bigTitle"> 公司服务内容</h2>
      <p class="subTitle"> Company service content</p>
    </div>
    <div class="Moservice-swiper">
      <van-swipe @change="onChange">
        <van-swipe-item class="content1">
          <div class="content" :style="isAcive ? acStyle : ''">
            <h2>TapTap业务</h2>
            <h3>
              <span class="title-point bk-color-1"></span>
              方案策划
            </h3>
            <p class="title-content">1. 产品市场初步基础调研+产品社区运营方案;</p>
            <p class="title-content">2. 社区运营排期表;</p>
            <h3>
              <span class="title-point bk-color-1"></span>
              基础搭建
            </h3>
            <p class="title-content">1. 社区基础搭建：社区版块，信息分类进行优化分类；</p>
            <p class="title-content">2. 官方账号基础搭建：进行用户回复及答疑，进行官方相关信息同步及物料发布；</p>
            <h3>
              <span class="title-point bk-color-1"></span>
              内容运营
            </h3>
            <p class="title-content">1. 活动策划：根据产品相关节点或其他时间阶段进行活动策划及执行；</p>
            <p class="title-content">2. 二创内容策划：游戏攻略，同人周边，游玩视频等内容创作及发布；</p>
            <h3>
              <span class="title-point bk-color-1"></span>
              舆情监控
            </h3>
            <p class="title-content">1.社区舆情维护:及时清理论坛板块相关广告及不符合社区管理规定的帖子；</p>
            <p class="title-content">
              2.社区舆情监控:特殊舆情及时反馈,常见舆情进行定时统一汇报；汇总评论监测、官方论坛行为监测、以及视频区内容监测；
            </p>
            <p class="title-content">
              3.竞品监控报告:对于指定竞品在平台专区的数据监控。（包含评论监测、官方论坛行为监测、以及视频区内容监测）
            </p>
            <h3>
              <span class="title-point bk-color-1"></span>
              深度运营
              </h3>
            <p class="title-content">1.筛选并发布与产品相关优质视频</p>
            <p class="title-content">2.社群运营：精细化管理玩家、挖掘并集中优质UP主进行优质内容产出；</p>
            <h3>
              <span class="title-point bk-color-1"></span>
              运营报告
            </h3>
            <p class="title-content">1.运营报告:以日、周、月或在规定时间段发送运营报告；</p>
            <P class="title-content"> 2.数据分析:汇总分析项目数据变化,及项目期间运营效果分析。</P>
          </div>
          <p class="showHid" @click="clickHandler" v-if="ifcz">{{ btns }}</p>
        </van-swipe-item>
        <van-swipe-item class="content2">
          <div class="content" :style="isAcive ? acStyle : ''">
            <h2 class="title-2">bilibili业务</h2>
            <h3>
              <span class="title-point bk-color-2"></span>
              方案策划
            </h3>
            <p class="title-content">1. 产品市场初步基础调研+产品社区运营方案;</p>
            <p class="title-content">2. 社区运营排期表;</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              基础搭建
            </h3>
            <p class="title-content">1. 社区基础搭建：社区版块，信息分类进行优化分类；</p>
            <p class="title-content">2. 官方账号基础搭建：进行用户回复及答疑，进行官方相关信息同步及物料发布；</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              内容运营
            </h3>
            <p class="title-content">1. 活动策划：根据产品相关节点或其他时间阶段进行活动策划及执行；</p>
            <p class="title-content">2. 二创内容策划：游戏攻略，同人周边，游玩视频等内容创作及发布；</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              舆情监控
            </h3>
            <p class="title-content">1.社区舆情维护:及时清理论坛板块相关广告及不符合社区管理规定的帖子；</p>
            <p class="title-content">
              2.社区舆情监控:特殊舆情及时反馈,常见舆情进行定时统一汇报；汇总评论监测、官方论坛行为监测、以及视频区内容监测；
            </p>
            <h3>
              <span class="title-point bk-color-2"></span>
              深度运营
            </h3>
            <p class="title-content"> 1.筛选并发布与产品相关优质视频；</p>
            <P class="title-content">2.社群运营：精细化管理玩家、挖掘并集中优质UP主进行优质内容产出；</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              运营报告
            </h3>
            <p class="title-content">1.运营报告:以日、周、月或在规定时间段发送运营报告；</p>
            <p class="title-content">2.数据分析:汇总分析项目数据变化,及项目期间运营效果分析。</p>
          </div>
          <p class="showHid" @click="clickHandler" v-if="ifcz">{{ btns }}</p>
        </van-swipe-item>
        <van-swipe-item class="content3">
          <div class="content" :style="isAcive ? acStyle : ''">
            <h2 class="title-3">硬核渠道及其他渠道业务</h2>
            <h3>
              <span class="title-point bk-color-3"></span>
              方案策划
            </h3>
            <p class="title-content">1. 产品市场初步基础调研+产品社区运营方案;</p>
            <p class="title-content">2. 社区运营排期表;</p>
            <h3>
              <span class="title-point bk-color-3"></span>
              基础搭建
            </h3>
            <p class="title-content">1. 社区基础搭建：社区版块，信息分类进行优化分类；</p>
            <p class="title-content">2. 官方账号基础搭建：进行用户回复及答疑，进行官方相关信息同步及物料发布；</p>
            <p class="title-content">3.优化游戏详情页内容：优化五图，简介等信息，提升转化率；</p>
            <h3>
              <span class="title-point bk-color-3"></span>
              内容运营
            </h3>
            <p class="title-content">1. 活动策划：根据产品相关节点或其他时间阶段进行活动策划及执行；</p>
            <p class="title-content">2. 二创内容策划：游戏攻略，同人周边，游玩视频等内容创作及发布；</p>
            <h3>
              <span class="title-point bk-color-3"></span>
              舆情监控
            </h3>
            <p class="title-content">1.社区舆情维护:及时清理论坛板块相关广告及不符合社区管理规定的帖子；</p>
            <p class="title-content">
              2.社区舆情监控:特殊舆情及时反馈,常见舆情进行定时统一汇报；汇总评论监测、官方论坛行为监测、以及视频区内容监测；
            </p>
            <h3>
              <span class="title-point bk-color-3"></span>
              深度运营
            </h3>
            <p class="title-content"> 1.筛选并发布与产品相关优质视频；</p>
            <h3>
              <span class="title-point bk-color-3"></span>
              运营报告
            </h3>
            <p class="title-content">1.运营报告:以日、周、月或在规定时间段发送运营报告；</p>
            <P class="title-content"> 2.数据分析:汇总分析项目数据变化,及项目期间运营效果分析。</P>
          </div>
          <p class="showHid" @click="clickHandler" v-if="ifcz">{{ btns }}</p>
        </van-swipe-item>
        <van-swipe-item class="content4">
          <div class="content" :style="isAcive ? acStyle : ''">
            <h2 class="title-4">小红书营销</h2>
            <h3>
              <span class="title-point bk-color-4"></span>
              素人笔记种草
            </h3>
            <p class="title-content">素人笔记铺垫，提高品牌曝以及口碑，维持热度;</p>
            <h3>
              <span class="title-point bk-color-4"></span>
              KOC真人种草
            </h3>
            <p class="title-content">发布产品种草笔记等，提高产品知名度。</p>
            <h3>
              <span class="title-point bk-color-4"></span>
              优质达人传播
            </h3>
            <p class="title-content">可以用作产品后期品牌宣传，撰写真实体验和原创图。</p>
            <h3>
              <span class="title-point bk-color-4"></span>
              线下探店
            </h3>
            <p class="title-content">通过线下打卡探店，输出文案，有深度并且多角度展示，提高品牌认知度。</p>
            <h3>
              <span class="title-point bk-color-4"></span>
              广告投放运营
            </h3>
            <p class="title-content">结合品牌推广和行业种草，同时也可以结合薯条一起，能作出较好的投放效果。</p>
          </div>
          <p class="showHid" @click="clickHandler" v-if="ifcz">{{ btns }}</p>
        </van-swipe-item>
        <van-swipe-item class="content5">
          <div class="content" :style="isAcive ? acStyle : ''">
            <h2>微博营销</h2>
            <h3>
              <span class="title-point bk-color-3"></span>
              微博热榜推广
            </h3>
            <p class="title-content">通过微博话题进行广告发布，提升品牌曝光量</p>
            <h3>
              <span class="title-point bk-color-3"></span>
              微博博文推广
            </h3>
            <p class="title-content">KOL及素人发布产品相关图文博文</p>
          </div>
          <p class="showHid" @click="clickHandler" v-if="ifcz">{{ btns }}</p>
        </van-swipe-item>
        <van-swipe-item class="content6">
          <div class="content" :style="isAcive ? acStyle : ''">
            <h2>微信营销</h2>
            <h3>
              <span class="title-point bk-color-6"></span>
              微信公众号
            </h3>
            <p class="title-content">粉丝分类多样化，精准进行信息推送及广告投放</p>
            <h3>
              <span class="title-point bk-color-6"></span>
              微信视频号
            </h3>
            <p class="title-content">短视频类推广热度较高，抓住平台用户，提高产品曝光度</p>
            <h3>
              <span class="title-point bk-color-6"></span>
              微信社群
            </h3>
            <p class="title-content">精细化管理产品社群，提高用户粘度</p>
          </div>
          <p class="showHid" @click="clickHandler" v-if="ifcz">{{ btns }}</p>
        </van-swipe-item>
        <van-swipe-item class="content2">
          <div class="content" :style="isAcive ? acStyle : ''">
            <h2>口碑营销</h2>
            <p class="title-content">口碑营销优势：从多角度，多平台发布多形式内容，为品牌赋能，建立优化品牌想象。</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              SEO营销
            </h3>
            <p class="title-content">撰写并发布媒体稿，提高品牌关键词搜索量</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              贴吧营销
            </h3>
            <p class="title-content">通过产品关键词筛选与产品高相关用户，并进行讨论；</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              微博营销
            </h3>
            <p class="title-content">通过曝光量巨大的泛用户平台，传播产品正面形象；</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              短视频营销
            </h3>
            <p class="title-content">在各类新媒体平台上发布相关视频，提高品牌曝光，建立品牌新形象；</p>
            <h3>
              <span class="title-point bk-color-2"></span>
              KOL营销
            </h3>
            <p class="title-content">各类平台网红大号发布产品相关内容，为产品获取高传播与转化；</p>
          </div>
          <p class="showHid" @click="clickHandler" v-if="ifcz">{{ btns }}</p>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css'; // 注意这里的引入
export default {
  name: 'MoComService',
  data() {
    return {
      isAcive: false,
      btns: '展开',
      ifcz: true,
      currentIndex: 0,
      containerLength: '',
      scrollHeight: '',
      acStyle: {
        height: '',
      },
    };
  },
  mounted() {
    let self = this;
    new Swiper('.moCardSwiper', {
      autoplay: false,
      loop: true,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        bulletElement: 'li',
        clickable: true,
      },

      slideToClickedSlide: true,
      centeredSlides: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        slideChangeTransitionEnd: function () {
          self.currentIndex = this.activeIndex;
        },
      },
    });

  },
  methods: {
    clickHandler(event) {
      console.log(event.target.innerText)
      if (event.target.innerText == '展开') {
        this.btns = '收起';
        this.isAcive = true;
        this.acStyle.height = document.getElementsByClassName("content")[this.currentIndex].scrollHeight + 'px'; //内容实际高度 + 'px'
        console.log( this.acStyle.height)

      } else {
        this.btns = '展开';
        this.isAcive = false;
      }
    },
    onChange(index) {
      this.currentIndex = index;
      console.log(this.currentIndex)
    },
  },
  watch: {
    currentIndex: function (curVal, oldVal) {
      this.containerLength = document.getElementsByClassName("content")[curVal].clientHeight; //盒子高度
      this.scrollHeight = document.getElementsByClassName("content")[curVal].scrollHeight; //内容实际高度
      console.log(this.scrollHeight)
      if (this.containerLength == this.scrollHeight) {
        this.ifcz = false
      } else {
        this.ifcz = true
      }
      if (curVal != oldVal) {
        this.btns = '展开';
        this.isAcive = false;
      }
    },
  },
};
</script>
<style lang="scss">
.Moservice {
  padding: 0 0 40px;
  background: #fafafa;

  .title {
    text-align: center;

    .bigTitle {
      font-size: 0.9375rem;
      color: #222222;
      margin: 0 0 0.1875rem;
    }

    .subTitle {
      font-size: 0.5rem;
      color: #666666;
      margin-bottom: 1.25rem;
    }
  }
}

.Moservice-swiper {
  width: 100%;
  position: relative;
  // .swiper-container {
  //   width: 80%;
  // }
  // .swiper-slide {
  //   width: 100%;
  // }
  // .swiper-wrapper{
  //   height:18.75rem;
  // }
  // .pagination {
  //   position: absolute;
  //   z-index: 20;
  //   bottom: 10px;
  //   width: 100%;
  //   text-align: center;
  // }

  // .swiper-pagination-switch {
  //   display: inline-block;
  //   width: 8px;
  //   height: 8px;
  //   border-radius: 8px;
  //   background: #555;
  //   margin: 0 5px;
  //   opacity: 0.8;
  //   border: 1px solid #fff;
  //   cursor: pointer;
  // }


.van-swipe{
  width: 15.625rem;
  margin: auto;
  padding:0 0 1.25rem 0;
  .van-swipe__indicators{
    .van-swipe__indicator{
      width: 0.4688rem;
      height: 0.4688rem;
      background-color: #888;
    }
    .van-swipe__indicator--active{
      width: 0.9375rem;
      border-radius: 0.3125rem;
      background-color: #1989fa;
    }
  }
}
  .content {
    text-align: left;
    height: 16.875rem;
    overflow: hidden;
    padding: 0.625rem 0.75rem;

    h2 {
      font-size: 0.875rem;
    }

    .title-point {
      width: 0.25rem;
      height: 0.25rem;
      text-align: center;
      line-height: 0.375rem;
      font-weight: bold;
      font-size: 0.5rem;
      display: inline-block;
      border-radius: 50%;
      margin-right: 0.1875rem;
    }

    h3 {
      font-size: 0.5625rem;
      color: #222222;
    }

    .title-content {
      color: #666666;
      font-size: 0.5rem;
    }

    .bk-color-1 {
      background: rgb(21, 197, 206);
    }

    .bk-color-2 {
      background: rgb(247, 115, 153);
    }

    .bk-color-3 {
      background: #ffb14a;
    }

    .bk-color-4 {
      background: #f25822;
    }

    .bk-color-6 {
      background: #1dc689;
    }
    .bk-color-6 {
      background: #369ef7;
    }
  }

  .showHid {
    position: absolute;
    font-size: 0.5625rem;
    padding: 0.25rem 0;
    margin: 0;
    width: 100%;
    height: 0.9375rem;
    text-align: center;
    background: #fff;
    opacity: 0.6;
  }

  .content1 {
    height: 100%;
    background: url('@/assets/images/pricing 01.png') no-repeat;
    background-size: 100% 100%;
    position: relative;

    h2 {
      color: rgb(21, 197, 206);
      margin-top: 1.375rem;
    }

    .showHid {
      color: #8d64ff;
      bottom: 0;
    }
  }

  .content2 {
    background-image: url('@/assets/images/pricing 02.png');
    background-size: 100% 100%;

    h2 {
      color: rgb(247, 115, 153);
      margin-top: 1.375rem;
    }

    .showHid {
      color: #369ef7;
      bottom: 0;
    }
  }

  .content3 {
    background-image: url('@/assets/images/pricing 03.png');
    background-size: 100% 100%;

    h2 {
      color: #ffb14a;
      margin-top: 1.375rem;
    }

    .showHid {
      color: #ffb14a;
      bottom: 0;
    }
  }

  .content4 {
    background-image: url('@/assets/images/pricing 04.png');
    background-size: 100% 100%;

    h2 {
      color: #f25822;
      margin-top: 1.375rem;
    }
     .showHid {
      color: #f25822;
      bottom: 0;
    }
  }

  .content5 {
    background-image: url('@/assets/images/pricing 03.png');
    background-size: 100% 100%;

    h2 {
      color: #ffb14a;
      margin-top: 1.375rem;
    }
  }

  .content6 {
    background-image: url('@/assets/images/pricing 05.png');
    background-size: 100% 100%;

    h2 {
      color: #1dc689;
      margin-top: 1.375rem;
    }
  }

  .content7 {
    background-image: url('@/assets/images/pricing 06.png');
    background-size: 100% 100%;

    h2 {
      color: #369ef7;
      margin-top: 1.375rem;
    }
  }
}
</style>
