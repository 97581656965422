<template>
  <div class="Com-Container">
    <div class=" title">
      <h2 class="bigTitle"> 公司服务内容</h2>
      <p class="subTitle"> Company service content</p>
    </div>
    <div class="app-container">
      <div class="swiper-container cardSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide1 divs content1">
              <h2>TapTap业务</h2>
            <div class="content">
                <h3>
                  <span class="title-point bk-color-1"></span>
                  方案策划
                </h3>
                <p class="title-content">1. 产品市场初步基础调研+产品社区运营方案;</p>
                <p class="title-content">2. 社区运营排期表;</p>
                <h3>
                  <span class="title-point bk-color-1"></span>
                  基础搭建
                </h3>
                <p class="title-content">1. 社区基础搭建：社区版块，信息分类进行优化分类；</p>
                <p class="title-content">
                  2.
                  官方账号基础搭建：进行用户回复及答疑，进行官方相关信息同步及物料发布；
                </p>
                <h3>
                  <span class="title-point bk-color-1"></span>
                  内容运营
                </h3>
                <p class="title-content">1. 活动策划：根据产品相关节点或其他时间阶段进行活动策划及执行；</p>
                <p class="title-content">2. 二创内容策划：游戏攻略，同人周边，游玩视频等内容创作及发布；</p>
                <h3>
                  <span class="title-point bk-color-1"></span>
                  舆情监控
                </h3>
                <p class="title-content">1.社区舆情维护:及时清理论坛板块相关广告及不符合社区管理规定的帖子；</p>
                <p class="title-content">2.社区舆情监控:特殊舆情及时反馈,常见舆情进行定时统一汇报；汇总评论监测、官方论坛行为监测、以及视频区内容监测；</p>
                <p class="title-content">3.竞品监控报告:对于指定竞品在平台专区的数据监控。（包含评论监测、官方论坛行为监测、以及视频区内容监测）</p>
                <h3>
                  <span class="title-point bk-color-1"></span>
                  深度运营
                  </h3>
                <p class="title-content">1.筛选并发布与产品相关优质视频</p>
              <p class="title-content">2.社群运营：精细化管理玩家、挖掘并集中优质UP主进行优质内容产出；</p>
                <h3>
                  <span class="title-point bk-color-1"></span>
                  运营报告
                  </h3>
                <p class="title-content">1.运营报告:以日、周、月或在规定时间段发送运营报告；</p>
                <P class="title-content"> 2.数据分析:汇总分析项目数据变化,及项目期间运营效果分析。</p>

            </div>
          </div>
          <div class="swiper-slide swiper-slide1 divs content2">
            <h2 class="title-2">bilibili业务</h2>
            <div class="content">

              <h3>
                <span class="title-point bk-color-2"></span>
                方案策划
              </h3>
              <p class="title-content">1. 产品市场初步基础调研+产品社区运营方案;</p>
              <p class="title-content">2. 社区运营排期表;</p>
              <h3>
                <span class="title-point bk-color-2"></span>
                基础搭建
              </h3>
              <p class="title-content">1. 社区基础搭建：社区版块，信息分类进行优化分类；</p>
              <p class="title-content">2. 官方账号基础搭建：进行用户回复及答疑，进行官方相关信息同步及物料发布；</p>
              <h3>
                <span class="title-point bk-color-2"></span>
                内容运营
              </h3>
              <p class="title-content">1. 活动策划：根据产品相关节点或其他时间阶段进行活动策划及执行；</p>
              <p class="title-content">2. 二创内容策划：游戏攻略，同人周边，游玩视频等内容创作及发布；</p>
              <h3>
                <span class="title-point bk-color-2"></span>
                舆情监控
              </h3>
              <p class="title-content">1.社区舆情维护:及时清理论坛板块相关广告及不符合社区管理规定的帖子；</p>
              <p class="title-content">2.社区舆情监控:特殊舆情及时反馈,常见舆情进行定时统一汇报；汇总评论监测、官方论坛行为监测、以及视频区内容监测；</p>
              <h3>
                <span class="title-point bk-color-2"></span>
                深度运营</h3>
              <p class="title-content"> 1.筛选并发布与产品相关优质视频；</p>
              <P class="title-content">2.社群运营：精细化管理玩家、挖掘并集中优质UP主进行优质内容产出；</p>
              <h3>
                <span class="title-point bk-color-2"></span>
                运营报告
              </h3>
              <p class="title-content">1.运营报告:以日、周、月或在规定时间段发送运营报告；</p>
              <P class="title-content"> 2.数据分析:汇总分析项目数据变化,及项目期间运营效果分析。</p>
            </div>
          </div>
          <div class="swiper-slide swiper-slide1 divs content3">
              <h2 class="title-3">硬核渠道及其他渠道业务</h2>
            <div class="content">

              <h3>
                <span class="title-point bk-color-3"></span>
                方案策划
              </h3>
              <p class="title-content">1. 产品市场初步基础调研+产品社区运营方案;</p>
              <p class="title-content">2. 社区运营排期表;</p>
              <h3>
                <span class="title-point bk-color-3"></span>
                基础搭建
              </h3>
              <p class="title-content">1. 社区基础搭建：社区版块，信息分类进行优化分类；</p>
              <p class="title-content">
                2.
                官方账号基础搭建：进行用户回复及答疑，进行官方相关信息同步及物料发布；
              </p>
              <p class="title-content">
                3.优化游戏详情页内容：优化五图，简介等信息，提升转化率；
              </p>
              <h3>
                <span class="title-point bk-color-3"></span>
                内容运营
              </h3>
              <p class="title-content">1. 活动策划：根据产品相关节点或其他时间阶段进行活动策划及执行；</p>
              <p class="title-content">2. 二创内容策划：游戏攻略，同人周边，游玩视频等内容创作及发布；</p>
              <h3>
                <span class="title-point bk-color-3"></span>
                舆情监控
              </h3>
              <p class="title-content">1.社区舆情维护:及时清理论坛板块相关广告及不符合社区管理规定的帖子；</p>
              <p class="title-content">2.社区舆情监控:特殊舆情及时反馈,常见舆情进行定时统一汇报；汇总评论监测、官方论坛行为监测、以及视频区内容监测；</p>
              <h3>
                <span class="title-point bk-color-3"></span>
                深度运营
              </h3>
              <p class="title-content"> 1.筛选并发布与产品相关优质视频；</p>
              <h3>
                <span class="title-point bk-color-3"></span>
                运营报告
              </h3>
              <p class="title-content">1.运营报告:以日、周、月或在规定时间段发送运营报告；</p>
              <P class="title-content"> 2.数据分析:汇总分析项目数据变化,及项目期间运营效果分析。</p>
            </div>
          </div>
          <div class="swiper-slide swiper-slide1 divs content4">
              <h2 class="title-4">小红书营销</h2>
            <div class="content">

              <h3>
                <span class="title-point bk-color-4"></span>
                素人笔记种草
              </h3>
              <p class="title-content">素人笔记铺垫，提高品牌曝以及口碑，维持热度;</p>
              <h3>
                <span class="title-point bk-color-4"></span>
                KOC真人种草
              </h3>
              <p class="title-content">发布产品种草笔记等，提高产品知名度。</p>
              <h3>
                <span class="title-point bk-color-4"></span>
                优质达人传播
              </h3>
              <p class="title-content">可以用作产品后期品牌宣传，撰写真实体验和原创图。</p>
              <h3>
                <span class="title-point bk-color-4"></span>
                线下探店
              </h3>
              <p class="title-content">通过线下打卡探店，输出文案，有深度并且多角度展示，提高品牌认知度。</p>
              <h3>
                <span class="title-point bk-color-4"></span>
                广告投放运营
              </h3>
              <p class="title-content">结合品牌推广和行业种草，同时也可以结合薯条一起，能作出较好的投放效果。</p>
            </div>
          </div>
          <div class="swiper-slide swiper-slide1 divs content5">
             <h2>微博营销</h2>
            <div class="content">

              <h3>
                <span class="title-point bk-color-3"></span>
                微博热榜推广
              </h3>
              <p class="title-content">通过微博话题进行广告发布，提升品牌曝光量</p>
              <h3>
                <span class="title-point bk-color-3"></span>
                微博博文推广
              </h3>
              <p class="title-content">KOL及素人发布产品相关图文博文</p>
            </div>
          </div>
          <div class="swiper-slide swiper-slide1 divs content6">
            <h2>微信营销</h2>
            <div class="content">

              <h3>
                <span class="title-point bk-color-6"></span>
                微信公众号
              </h3>
              <p class="title-content">粉丝分类多样化，精准进行信息推送及广告投放</p>
              <h3>
                <span class="title-point bk-color-6"></span>
                微信视频号
              </h3>
              <p class="title-content">短视频类推广热度较高，抓住平台用户，提高产品曝光度</p>
              <h3>
                <span class="title-point bk-color-6"></span>
                微信社群
              </h3>
              <p class="title-content">精细化管理产品社群，提高用户粘度</p>
            </div>
          </div>
          <div class="swiper-slide swiper-slide1 divs content7">
               <h2>口碑营销</h2>
            <div class="content">

              <p class="title-content">口碑营销优势：从多角度，多平台发布多形式内容，为品牌赋能，建立优化品牌想象。</p>
              <h3>
                <span class="title-point bk-color-7"></span>
                SEO营销
              </h3>
              <p class="title-content">撰写并发布媒体稿，提高品牌关键词搜索量</p>
              <h3>
                <span class="title-point bk-color-7"></span>
                论坛营销
              </h3>
              <p class="title-content">在各大论坛发布宣传帖子</p>
              <h3>
                <span class="title-point bk-color-7"></span>
                贴吧营销
              </h3>
              <p class="title-content">通过产品关键词筛选与产品高相关用户，并进行讨论；</p>
              <h3>
                <span class="title-point bk-color-7"></span>
                微博营销
              </h3>
              <p class="title-content">通过曝光量巨大的泛用户平台，传播产品正面形象；</p>
              <h3>
                <span class="title-point bk-color-7"></span>
                短视频营销
              </h3>
              <p class="title-content">在各类新媒体平台上发布相关视频，提高品牌曝光，建立品牌新形象；</p>
              <h3>
                <span class="title-point bk-color-7"></span>
                KOL营销
              </h3>
              <p class="title-content">各类平台网红大号发布产品相关内容，为产品获取高传播与转化；</p>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
        <!-- 如果需要导航按钮 -->
        <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
      </div>
      <div class="swiper-button-prev" ref="prev" @mouseover="mouseoverPre" @mouseout="mouseoutPre"></div>
      <div class="swiper-button-next" ref="next" @mouseover="mouseovernext" @mouseout="mouseoutnext"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    new Swiper(".cardSwiper", {
      // autoplay: {
      //   delay: 1000, // 轮播时长
      //   stopOnLastSlide: false,
      //   disableOnInteraction: false,
      // },
      autoplay: false,
      loop: true,
      // 一页出现几个
      slidesPerView: 3,
      // 设置间距
      spaceBetween: 30,
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        bulletElement: "li",
        clickable: true
      },

      slideToClickedSlide: true,
      centeredSlides: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  methods: {
    mouseoverPre() {
      this.$refs["prev"].style.background = "#369EF7";
      this.$refs["prev"].style.color = "#fff";
    },
    mouseoutPre() {
      this.$refs["prev"].style.background = "#fff";
      this.$refs["prev"].style.color = "#333";
    },
    mouseovernext() {

      this.$refs["next"].style.background = "#369EF7";
      this.$refs["next"].style.color = "#fff";
    },
    mouseoutnext() {
      this.$refs["next"].style.background = "#fff";
      this.$refs["next"].style.color = "#333";
    }
  }
};
</script>

<style lang="scss" scoped>
.Com-Container {
  width: 100%;
  background: #fafafa;
  padding: 40px 0;

  .title {
    text-align: center;

    .bigTitle {
      font-size: 30px;
      color: #222222;
      margin: 0 0 6px;
    }

    .subTitle {
      font-size: 16px;
      color: #666666;
      margin-bottom: 40px;
    }
  }

  .swiper-container {
    height: 700px;
  }

  .swiper-button-next {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    right: -117px;
    left: auto;
    background: #fff;
    color: #333;
    outline: none;
  }

  .swiper-button-prev {
    left: -117px;
    right: auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fff;
    color: #333;
    outline: none;
  }

  .app-container {
    width: 1270px;
    height: 700px;
    margin: auto;
    position: relative;
  }

  .divs {
    // 向上挤出40px
    margin-top: 40px;
    height: 540px;
    width: 100%;
  }

  .content {
    text-align: left;
    height: 450px;
    padding: 0 24px 60px;
    .title-point {
      width: 8px;
      height: 8px;
      text-align: center;
      line-height: 12px;
      font-weight: bold;
      font-size: 12px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 6px;
    }

    h3 {
      color: #222222;
    }

    .title-content {
      color: #666666;
    }

    .bk-color-1 {
      background: rgb(21, 197, 206);
    }

    .bk-color-2 {
      background: rgb(247, 115, 153);
    }

    .bk-color-3 {
      background: #ffb14a;
    }

    .bk-color-4 {
      background: #f25822;
    }

    .bk-color-6 {
      background: #1dc689;
    }
    .bk-color-7{
       background: #369ef7;
    }
  }

  .content1 {
    background-image: url("@/assets/images/pricing 01.png");

    h2 {
      color: rgb(21, 197, 206);
    }
  }

  .content2 {
    background-image: url("@/assets/images/pricing 02.png");

    h2 {
      color: rgb(247, 115, 153);
    }
  }

  .content3 {
    background-image: url("@/assets/images/pricing 03.png");

    h2 {
      color: #ffb14a;
    }
  }

  .content4 {
    background-image: url("@/assets/images/pricing 04.png");

    h2 {
      color: #f25822;
    }
  }

  .content5 {
    background-image: url("@/assets/images/pricing 03.png");

    h2 {
      color: #ffb14a;
    }
  }

  .content6 {
    background-image: url("@/assets/images/pricing 05.png");

    h2 {
      color: #1dc689;
    }
  }

  .content7 {
    background-image: url("@/assets/images/pricing 06.png");

    h2 {
      color: #369ef7;
    }
  }

  // 粗一点
  // ::-webkit-scrollbar {
  //   /*滚动条整体样式*/
  //   width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  //   height: 5px;
  //   scrollbar-arrow-color: red;
  // }

  // ::-webkit-scrollbar-thumb {
  //   /*滚动条里面小方块*/
  //   border-radius: 5px;
  //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  //   background: rgba(0, 0, 0, 0.2);
  //   scrollbar-arrow-color: red;
  // }

  // ::-webkit-scrollbar-track {
  //   /*滚动条里面轨道*/
  //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  //   border-radius: 0;
  //   background: rgba(0, 0, 0, 0);
  // }

  // 特细
  //滚动条css
  // ::-webkit-scrollbar {
  //   /*滚动条整体样式*/
  //   width: 2px;
  //   /*高宽分别对应横竖滚动条的尺寸*/
  //   height: 6px;
  // }

  // ::-webkit-scrollbar-thumb {
  //   /*滚动条里面小方块*/
  //   border-radius: 5px;
  //   // box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  //   background: #007cff;
  // }

  // ::-webkit-scrollbar-track {
  //   /*滚动条里面轨道*/
  //   // box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  //   /*border-radius: 5px;*/
  //   /*background: #d8d8d8;*/
  // }

  .pagination {
    position: absolute;
    z-index: 20;
    bottom: 10px;
    width: 100%;
    text-align: center;
    outline: none;
  }

  .swiper-pagination {
    bottom: 63px;
  }
  /deep/.swiper-pagination-bullet{
    width: 15px;
    height:15px;
  }
  .swiper-pagination /deep/ .swiper-pagination-bullet-active{
    width: 30px;
    border-radius: 30px;
  }
  .swiper-pagination-switch {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #555;
    margin: 0 5px;
    opacity: 0.8;
    border: 1px solid #fff;
    cursor: pointer;
  }
 .swiper-slide{
   overflow: hidden;
     h2 {
      margin: 20px 20px 20px;
    }
 }
  .swiper-slide-prev,
  .swiper-slide-next {
    .content {
      overflow: hidden;
    }
  }

  // 轮播特殊那个
  .swiper-slide-active {
    color: #fff;
    transform: translateY(-10px);

    .content {
      overflow-x: auto;
    }

    .content::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 2px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
  }
}
</style>
