<template>
   <div class="MoHeader">
       <div class = "logoImage">
         <img src="xcLogo.svg" class="logo"/>
          <img src="@/assets/images/email.png">
       </div>
       <div class="Icon">
          <img src="@/assets/images/email.png">
          <img src="@/assets/images/phone.png">
       </div>
   </div>
</template>
<script>
  export default{
      name:'MoHead'
  }
</script>
<style lang="scss">
  .MoHeader{
    width: 100%;
    height: 2.125rem;
    background: #369EF7;
    overflow: hidden;
    display: flex;
    .logoImage{
       width: 3.3125rem;
       height: 1.25rem;
       margin: auto;
       margin-left:1.25rem;
       margin-top: 0.4375rem;
       .logo{
        width: 100%;
        height:100%;
       }
    }
    .Icon{
       width:2.6688rem;
       height:0.825rem;
       display: flex;
       margin-right:2.5rem;
       margin-top: 0.75rem;
       align-items: center;
       img{
        width:1.125rem;
        height:1.125rem;
       }
       img:last-child{
        margin-left: 1.375rem;
       }
    }
  }
</style>
