<template>
  <div class="Mobanner">
     <div class="bottom-img">
       <img src="@/assets/images/top-pic-mobile.png" class='top_banner'/>
      <img src="@/assets/images/right-bottombanner.png" class="right-bottombanner">
    </div>
  </div>
</template>
<script>
export default {
  name: "MoBanner",
    props:{
    ifmobile: {
      type: Boolean,
      default: false
    },
  },
};
</script>
<style lang = "scss">
  .top_banner{
    width: 100%;
    object-fit: cover;
  }
   .Mobanner{
     width: 100%;

     background-image: url("@/assets/images/top-banner-web.png");
     overflow:hidden;
     position: relative;
      .bottom-img{
         top:0;
        .bottom-img-bg{
          width: 100%;
          height: 100%;
        }
        .right-bottombanner{
           width: 228px;
           height: 303px;
           position: absolute;
           right: 0;
           bottom:0;
        }
       }
       .banner-components{
          height:80%;
          margin-left:10%;
          display:flex;
          justify-content:space-between;
          align-items: center;
       }
   }

</style>
