<template>
  <div id="app">
    <div class="FootNav">
      <div class="foot-nav">
        <ul>
          <li>地址:西安市雁塔区科技二路竹园·天寰国际</li>
          <li>联系人:尹先生</li>
        </ul>
        <img src="xcLogo.svg" class="logo">
      </div>
      <div class="showEmail" style="display: flex;flex-wrap: wrap;">
        <!-- <a href="http://www.starmob.net/" style="color:#fff">Copyright © xingcanhuyu 2022. All Rights Reserved.</a> -->
         <a href="./xiniuGame.html">Copyright © 2019 陕西星璨互娱文化传播有限公司</a> |
         <a href="https://beian.miit.gov.cn/">陕ICP备18012961号-1</a> |
         <a @click="clickImg">陕网文【2019】0765-021号</a> |
          <a @click="clickImg2">陕B2-20190105</a>
         <a  @click="clickImg"><img src="@/assets/images/xiniuGame/wen.png" style="width: 30px"></a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootNav",
  methods:{
    clickImg(){
      let str = require("@/assets/images/xiniuGame/jyz.jpg")
      window.open(str)
    },
    clickImg2(){
      let str = require("@/assets/images/xiniuGame/dxjyz.png")
      window.open(str)
    }
  }
};
</script>

<style lang="scss" scoped>
.FootNav {
  width: 100wh;
  height: 400px;
  background: #369ef7;
  overflow: hidden;
  position: relative;
  right: 0;
  left: 0;
  .foot-nav {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #cccccc;
    padding-bottom: 20px;
  }
  ul {
    width: 550px;
    text-align: left;
    margin-top: 131px;
    li {
      list-style: none;
      color: #fff;
      font-size: 24px;
    }
    li:nth-child(2) {
      margin: 25px 0;
    }
  }
  .logo {
    width: 200px;
    margin-top: 131px;
  }
  .showEmail {
    width: 1000px;
    margin: auto;
    margin-top: 30px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    align-items: center;
    a{
      margin:0 10px;
      color: #fff;
    }
    a:hover{
      color:#403BD6;
    }
  }
}
</style>
